<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    persistent
    width="900"
  >
    <v-card flat>
      <v-card-title class="grey">
        <div class="heading-4 font-weight-bold">
          {{ isEdit ? "Edit Image" : "Added Image" }}
        </div>
        <v-spacer />
        <v-icon class="font-weight-bold" @click="close()"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6">
              <div v-if="form.content">
                <v-img :src="form.content" @click="onButtonClick" />
              </div>
              <div
                v-else
                id="upload-image"
                class="d-flex black--text justify-center align-center"
                @click="onButtonClick"
              >
                Upload Image
              </div>
              <input
                v-show="false"
                ref="upload"
                type="file"
                accept="image/*"
                @change="onFileChanged"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.title"
                :rules="[(v) => !!v || 'Image title must be fill']"
                name="title"
                label="Image Title"
                dense
                outlined
              />
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
                aria-placeholder="Description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="my-2" />
      <v-card-actions class="d-flex justify-center">
        <v-btn width="150" color="#04BF68" dark depressed @click="save()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { isURL } from "@/utils/validate";

export default {
  props: {
    dialog: Boolean,
    selectedData: Object,
    isEdit: Boolean,
    loading: Boolean,
  },
  components: {
    quillEditor,
  },
  watch: {
    dialog(data) {
      if (data && this.isEdit) {
        this.form = { ...this.selectedData };
      } else {
        this.$nextTick(() => {
          this.form.oldImage = null;
          this.form.file = null;
          this.form.content = "";
          this.form.description = "";
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  data() {
    return {
      valid: false,
      form: {
        title: "",
        description: "",
        oldImage: null,
        file: null,
        content: "",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    save() {
      const isFormValid = this.$refs.form.validate();
      const isImageFill =
        this.form.content !== null && this.form.content !== "";
      if (isFormValid && isImageFill) {
        this.$emit("save", this.form);
      }
      if (!isImageFill) {
        this.snackBar(false, "Image must be fill");
      }
    },
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      if (e.target.files.length > 0) {
        if (isURL(this.form.content)) {
          this.form.oldImage = this.form.content;
        }
        const file = e.target.files[0];
        this.form.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.form.content = e.target.result;
        };
      }
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  cursor: pointer;
  height: 300px;
}
</style>
