<template>
  <v-sheet>
    <v-tabs v-model="tab" class="ml-4" @change="tabValue">
      <v-tabs-slider color="#04BF68" />

      <v-tab> Videos </v-tab>
      <v-tab> Images </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="pa-3">
          <v-col cols="12" md="9">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              depressed
              color="#04BF68"
              dark
              @click="showDialogVideo()"
            >
              Add Video
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <!-- <v-select
              :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
              label="Filter"
              dense
              outlined
            /> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mx-2">
            <v-data-table
              :headers="headersVideo"
              :items="data"
              :loading="loading"
              :server-items-length="total"
              item-key="id"
              show-expand
              @pagination="pagination"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="py-4">
                  <span v-if="item.description" v-html="item.description" />
                  <span v-else>No description</span>
                </td>
              </template>
              <template v-slot:[`item.image`]="{ item }">
                <v-img
                  :src="item.image"
                  class="ma-2"
                  width="80"
                  height="80"
                  @click="
                    () => {
                      viewImage = true;
                      urlFile = item.image;
                    }
                  "
                />
              </template>
              <template v-slot:[`item.content`]="{ item }">
                <a :href="item.content" target="_blank">
                  {{ item.content }}
                </a>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  color="primary"
                  fab
                  depressed
                  x-small
                  class="mx-2"
                  @click="showDialogVideo(item)"
                  ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
                >
                <v-btn
                  color="error"
                  fab
                  depressed
                  x-small
                  @click="deleteHandler(item)"
                  ><v-icon>mdi-delete-forever</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="pa-3">
          <v-col cols="12" md="9">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              depressed
              color="#04BF68"
              dark
              @click="showDialogImage()"
            >
              Add Image
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <!-- <v-select
              :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
              label="Filter"
              dense
              outlined
            /> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mx-2">
            <v-data-table
              :headers="headersImage"
              :items="data"
              :loading="loading"
              :server-items-length="total"
              item-key="id"
              show-expand
              @pagination="pagination"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="py-4">
                  <span v-if="item.description" v-html="item.description" />
                  <span v-else>No description</span>
                </td>
              </template>
              <template v-slot:[`item.content`]="{ item }">
                <v-img
                  :src="item.content"
                  class="ma-2"
                  width="80"
                  height="80"
                  @click="
                    () => {
                      viewImage = true;
                      urlFile = item.content;
                    }
                  "
                />
              </template>
              <template v-slot:[`item.link`]="{ item }">
                <a :href="item.link" target="_blank">
                  {{ item.link }}
                </a>
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <span v-html="item.description" />
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn color="#04BF68" fab dark depressed x-small>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  fab
                  depressed
                  x-small
                  class="mx-2"
                  @click="showDialogImage(item)"
                  ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
                >
                <v-btn
                  color="error"
                  fab
                  depressed
                  x-small
                  @click="deleteHandler(item)"
                  ><v-icon>mdi-delete-forever</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <form-video
      :dialog="dialogVideo"
      :selectedData="selectedData"
      :isEdit="isEdit"
      :loading="loadingForm"
      @close="dialogVideo = false"
      @save="saveGallery"
    />
    <form-image
      :dialog="dialogImage"
      :selectedData="selectedData"
      :isEdit="isEdit"
      :loading="loadingForm"
      @close="dialogImage = false"
      @save="saveGallery"
    />

    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Delete Gallery ${deleteData.title}?`"
      :loading="loadingForm"
      title="Delete Gallery"
      @action="(r) => deleteAction(r)"
    />
  </v-sheet>
</template>

<script>
import {
  getGallery,
  addGallery,
  editGallery,
  deleteGallery,
} from "@/api/admin/gallery";
import { uploadFile, removeFile } from "@/api/file";
import { isURL } from "@/utils/validate";

import ModalConfirm from "@/components/ModalConfirm.vue";
import ViewImage from "../../components/ViewImage.vue";
import FormVideo from "./FormVideo.vue";
import FormImage from "./FormImage.vue";

export default {
  components: {
    ModalConfirm,
    ViewImage,
    FormImage,
    FormVideo,
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    },
  },
  data() {
    return {
      isEdit: false,
      loading: false,
      loadingForm: false,
      dialogConfrim: false,
      dialogImage: false,
      dialogVideo: false,
      viewImage: false,
      search: "",
      searchValue: "",
      data: [],
      selectedData: {},
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      deleteData: {},
      form: { category: null },
      urlFile: "",
      viewFile: false,
      tab: this.$route.query.tab | 0,
      headersVideo: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Link",
          value: "content",
          width: 200,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 150,
        },
      ],
      headersImage: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Image",
          value: "content",
          width: 200,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 150,
        },
      ],
    };
  },
  mounted() {
    this.getGallery();
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getGallery();
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    async deleteAction(param) {
      if (param) {
        try {
          const { id, content } = this.deleteData;
          this.loadingForm = true;

          // delete image from server
          if (isURL(content)) {
            const delImage = await removeFile({ file: content });
            if (!delImage.data.code)
              this.snackBar(false, "Failed delete image");
          }

          const delCatalog = await deleteGallery({ id: id });
          if (delCatalog.data.code)
            this.snackBar(true, "Success delete gallery");
          else this.snackBar(false, "Failed delete gallery");
        } catch (error) {
          console.error("deleteAction()\n", error);
        } finally {
          this.loadingForm = false;
          this.dialogConfrim = false;
          this.getGallery();
        }
      } else this.dialogConfrim = false;
    },
    async saveGallery(item) {
      try {
        this.loadingForm = true;
        const isTabVideo = parseInt(this.tab) === 0;
        const body = {
          content_type: isTabVideo ? "VIDEO" : "IMAGE",
          ...item,
        };

        // Upload image
        if (item.file) {
          const form = new FormData();
          form.append("file", item.file);

          const uploadImage = await uploadFile(form);
          if (uploadImage.data.code) {
            body.content = uploadImage.data.data.path;
          } else this.snackBar(false, "Failed upload image");
        }

        // delete old image, if exist
        if (!isTabVideo) {
          if (this.isEdit) {
            if (isURL(item.oldImage)) {
              const delImage = await removeFile({ file: item.oldImage });
              if (!delImage.data.code)
                this.snackBar(false, "Failed delete image");
            }
          }
        }

        // Check form action
        if (this.isEdit) {
          const response = await editGallery(body);
          const { data } = response;
          this.snackBar(data.code === 1, data.message);
        } else {
          const response = await addGallery(body);
          const { data } = response;
          this.snackBar(data.code === 1, data.message);
        }
      } catch (error) {
        console.error("saveGallery()\n", error);
      } finally {
        this.loadingForm = false;
        this.dialogVideo = false;
        this.dialogImage = false;
        this.selectedData = {};
        this.getGallery();
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getGallery() {
      this.loading = true;
      getGallery({
        content_type: parseInt(this.tab) === 0 ? "VIDEO" : "IMAGE",
        ...this.query,
      })
        .then((res) => {
          if (res.data.code) {
            this.data = res.data.data.data;
            this.total = res.data.data.total;
          } else this.snackBar(false, res.data.message);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    tabValue(tab) {
      this.$router.push({ name: "Gallery", query: { tab: tab } });
      this.getGallery();
    },
    stringTruncate(str, length) {
      const dots = str.length > length ? "..." : "";
      return str.substring(0, length) + dots;
    },
    showSchoolYearForm(item) {
      this.dialogForm = true;
      if (item) {
        this.selectedData = item;
      }
    },
    showDialogVideo(item) {
      if (item) {
        this.selectedData = item;
        this.isEdit = true;
      } else this.isEdit = false;
      this.dialogVideo = true;
    },
    showDialogImage(item) {
      if (item) {
        this.selectedData = item;
        this.isEdit = true;
      } else this.isEdit = false;
      this.dialogImage = true;
    },
  },
};
</script>

<style lang="scss">
a {
  color: #83aef1 !important;
}
</style>
