<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    persistent
    width="500"
  >
    <v-card flat>
      <v-card-title class="grey">
        <div class="heading-4 font-weight-bold">
          {{ isEdit ? "Edit Video" : "Add Video" }}
        </div>
        <v-spacer />
        <v-icon :disabled="loading" class="font-weight-bold" @click="close()">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.title"
                :rules="[(v) => !!v || 'Video Title must be fill']"
                name="video-title"
                label="Video Title"
                dense
                outlined
              />
              <v-text-field
                v-model="form.content"
                :rules="[
                  (v) => !!v || 'Link Youtube must be fill',
                  (v) => isURL(v) || 'Link must be valid',
                ]"
                name="link-youtube"
                label="Link Youtube"
                dense
                outlined
              />
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
                aria-placeholder="Description"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn
                :loading="loading"
                width="150"
                color="#04BF68"
                dark
                depressed
                @click="save()"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  props: {
    dialog: Boolean,
    selectedData: Object,
    isEdit: Boolean,
    loading: Boolean,
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      valid: false,
      form: {
        title: "",
        content: "",
        description: "",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
    };
  },
  watch: {
    dialog(data) {
      if (data && this.isEdit) {
        this.form = { ...this.selectedData };
      } else {
        this.$nextTick(() => {
          this.form.description = "";
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  methods: {
    isURL(url) {
      if (!url) return false;
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))|" + // OR ip (v4) address
          "localhost" + // OR localhost
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return pattern.test(url);
    },
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", this.form);
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  cursor: pointer;
  height: 300px;
}
</style>
