import request from "@/utils/request";

export function getGallery(data) {
  return request({
    method: "post",
    url: "/galery/get",
    data,
  });
}

export function addGallery(data) {
  return request({
    method: "post",
    url: "/galery/add",
    data,
  });
}

export function editGallery(data) {
  return request({
    method: "post",
    url: "/galery/edit",
    data,
  });
}

export function deleteGallery(data) {
  return request({
    method: "post",
    url: "/galery/delete",
    data,
  });
}
