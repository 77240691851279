var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',[_c('v-tabs',{staticClass:"ml-4",on:{"change":_vm.tabValue},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"#04BF68"}}),_c('v-tab',[_vm._v(" Videos ")]),_c('v-tab',[_vm._v(" Images ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"#04BF68","dark":""},on:{"click":function($event){return _vm.showDialogVideo()}}},[_vm._v(" Add Video ")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('v-row',[_c('v-col',{staticClass:"mx-2",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersVideo,"items":_vm.data,"loading":_vm.loading,"server-items-length":_vm.total,"item-key":"id","show-expand":""},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[(item.description)?_c('span',{domProps:{"innerHTML":_vm._s(item.description)}}):_c('span',[_vm._v("No description")])])]}},{key:`item.image`,fn:function({ item }){return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.image,"width":"80","height":"80"},on:{"click":() => {
                    _vm.viewImage = true;
                    _vm.urlFile = item.image;
                  }}})]}},{key:`item.content`,fn:function({ item }){return [_c('a',{attrs:{"href":item.content,"target":"_blank"}},[_vm._v(" "+_vm._s(item.content)+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.showDialogVideo(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1),_c('v-btn',{attrs:{"color":"error","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.deleteHandler(item)}}},[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"#04BF68","dark":""},on:{"click":function($event){return _vm.showDialogImage()}}},[_vm._v(" Add Image ")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('v-row',[_c('v-col',{staticClass:"mx-2",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersImage,"items":_vm.data,"loading":_vm.loading,"server-items-length":_vm.total,"item-key":"id","show-expand":""},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[(item.description)?_c('span',{domProps:{"innerHTML":_vm._s(item.description)}}):_c('span',[_vm._v("No description")])])]}},{key:`item.content`,fn:function({ item }){return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.content,"width":"80","height":"80"},on:{"click":() => {
                    _vm.viewImage = true;
                    _vm.urlFile = item.content;
                  }}})]}},{key:`item.link`,fn:function({ item }){return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.link)+" ")])]}},{key:`item.description`,fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"#04BF68","fab":"","dark":"","depressed":"","x-small":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.showDialogImage(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1),_c('v-btn',{attrs:{"color":"error","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.deleteHandler(item)}}},[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,true)})],1)],1)],1)],1),_c('form-video',{attrs:{"dialog":_vm.dialogVideo,"selectedData":_vm.selectedData,"isEdit":_vm.isEdit,"loading":_vm.loadingForm},on:{"close":function($event){_vm.dialogVideo = false},"save":_vm.saveGallery}}),_c('form-image',{attrs:{"dialog":_vm.dialogImage,"selectedData":_vm.selectedData,"isEdit":_vm.isEdit,"loading":_vm.loadingForm},on:{"close":function($event){_vm.dialogImage = false},"save":_vm.saveGallery}}),_c('view-image',{attrs:{"viewFile":_vm.viewImage,"urlFile":_vm.urlFile},on:{"close":function($event){_vm.viewImage = false}}}),_c('ModalConfirm',{attrs:{"dialog":_vm.dialogConfrim,"text":`Delete Gallery ${_vm.deleteData.title}?`,"loading":_vm.loadingForm,"title":"Delete Gallery"},on:{"action":(r) => _vm.deleteAction(r)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }